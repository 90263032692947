<template>
  <div class="home">
    <a-card title="Receive From Shop" :bordered="true">
      After Sales Service # <a-input placeholder="Rma" style="width: 200px;"/>
    </a-card>
    <a-divider/>
    <a-row>
      <a-col :span="16">
        <a-descriptions title="Information">
          <a-descriptions-item label="RMA No: ">#RMA012345</a-descriptions-item>
          <a-descriptions-item label="Email: ">eahhing@apm.mc</a-descriptions-item>
          <a-descriptions-item label="Phone: ">+852 8888 8888</a-descriptions-item>
          <a-descriptions-item label="Created By">APM HK Element</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="8">
        <a-timeline>
          <a-timeline-item>Create RMA - 2021-03-06 08:00:00</a-timeline-item>
          <a-timeline-item>Send to Repair 2021-03-07 08:00:00</a-timeline-item>
          <a-timeline-item color="red">
            <template #dot><clock-circle-outlined style="font-size: 16px" /></template>
            Waiting Package 2021-03-07 08:00:00
          </a-timeline-item>
        </a-timeline>
      </a-col>
    </a-row>
    
    <a-table :data-source="data">
      <a-table-column-group>
        <template #title><span style="color: #1890ff">Product</span></template>
        <a-table-column key="sku" data-index="sku">
          <template #title><span style="color: #1890ff">Sku</span></template>
        </a-table-column>
        <a-table-column key="size" title="Size" data-index="size" />
      </a-table-column-group>
      <a-table-column key="repair_reason" title="Repair Reason" data-index="repair_reason" />
      <template #tags="{ text: repair_reason }">
        <span>
          <a-tag
            v-for="tag in repair_reason"
            :key="tag"
            :color="tag === 'loser' ? 'volcano' : tag.length > 5 ? 'geekblue' : 'green'"
          >
            {{ tag.toUpperCase() }}
          </a-tag>
        </span>
      </template>
      <a-table-column key="sku_image" title="Image" data-index="sku_image" />
    </a-table>
  </div>
</template>
<script>

  const columns = [
    {
      title: 'Repair No.',
      dataIndex: 'repair_no',
      key: 'repair_no',
    },
    {
      dataIndex: 'sku',
      key: 'sku',
      slots: {
        title: 'customTitle',
        customRender: 'sku',
      },
    },
    {
      title: 'Reasons',
      key: 'reasons',
      dataIndex: 'reasons',
      slots: {
        customRender: 'reasons',
      },
    },
    {
      title: 'Action',
      key: 'action',
      slots: {
        customRender: 'action',
      },
    },
  ]


  const data = [
    {
      sku: 'RC5727OX',
      size: 'NO-SIZE',
      repair_reason: 'Broken',
      sku_image: 'https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/r/c/rc5727ox-apm-monaco-mariner-chain-necklace.jpg'
    },
    {
      sku: 'AE13019OXY',
      size: 'NO-SIZE',
      repair_reason: 'Broken, Missing Piece',
      sku_image: 'https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/e/ae13019oxy-apm-monaco-mariner-chain-link-earrings.jpg'
    },
    {
      sku: 'A20448OX',
      size: '50',
      repair_reason: 'Broken',
      sku_image: 'https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/2/a20448ox_apm_monaco_ring_love_morse_code_ring.jpg'
    },
    {
      sku: 'AB3402M',
      size: 'L',
      repair_reason: 'Broken',
      sku_image: 'https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/b/ab3402m.jpg'
    },
  ]
  export default {
    data() {
      return {
        data,
        columns
      }
    },
    components: {
    // SmileOutlined,
    },
    mounted() {
    
    },
    setup() {
    
      return {  }
    },
  }
</script>
